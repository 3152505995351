.draw {
    height: 100%;
    width: 100%;
    position: relative;
}
#mainVariable {
    /* width: 1000px; */
    height: 500px;
    /* border: 1px solid #c3c3c3; */
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* padding-left: 10vw; */
    overflow-y: auto;
}
.radioMainContainer {
    position: relative;
    height: 80vh;
    margin-left: 1%;
    width: 99vw;
}

.radioItems {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 23%);
    grid-template-rows: repeat(6, 16%);

    column-gap: 2%;
}

#mainVariable tr {
    display: inline-table;
}
.back_btn_logic {
    height: 6vh;
    width: 8vw;
    border: 2px solid black;
    border-radius: 50px;
    background-color: #311b92;
    top: 80vh;
    left: 2vw;
    position: absolute;
    color: white;
    text-align: center;
    line-height: 35px;
    z-index: 1000;
    font-size: 1.5vw;
    outline: none;
    word-wrap: break-word;
}
.CSD {
    display: inline-block;
    /* height: 90%;
      width: 25%;
      margin-left: 72%;
      z-index : 1000;
      float: left;
      color: white;
      /* background-color: lightgray; */
    height: 1vh;
    /* width: 26%; */
    /* margin-left: 72%; */
    z-index: 1000;
    /* float: left; */
    color: white;
    /* background-color: lightgray; */
    position: absolute;
    right: 3vw;
    top: 0vh;
}

#main {
    width: 100vw;
    height: 500px;
    /* border: 1px solid #c3c3c3; */
    display: flex;
    flex-wrap: wrap;
    /* padding-left: 10vw; */
    flex-direction: row;
    overflow-y: auto;
}

#main tr {
    display: inline-table;
    width: '25%';
}
/* width: "25%",height:"60px",float:"left",position:"relative",backgroundColor:"lightgrey",marginLeft:"5%" */

/* Decision Panel */
.HexDecisionPanel_Container {
    height: 109%;
    width: 84%;
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 19999;
    overflow: hidden;
    /* border: 3px solid blue; */
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 25% 18% 32% 25%;
    border-radius: 11%;
}

.sub_Container1 {
    display: grid;
    grid-template-columns: 30% 50% 20%;
    grid-template-rows: 100%;
    grid-template-areas:
        'content1_text1 content1_text2 content1_text3'
        ' content1_text1 content1_text2 content1_text3'
        'content1_text1 content1_text2 content1_text3 ';
}
#content1_text1 {
    grid-area: content1_text1;
    position: relative;
}
#content1_text2 {
    grid-area: content1_text2;
    position: relative;
}
#content1_text3 {
    grid-area: content1_text3;
    position: relative;
}

/* second div */

.sub_Container2 {
    border-radius: 10px;
    margin: 0 10% 0 10%;
    background-color: #dff5f2;
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-template-rows: 80% 20%;
    grid-template-areas:
        'content_div1 content_div2 content_div3 content_div4 content_div5'
        'sensors-txt1 sensors-txt2 sensors-txt3 sensors-txt4 sensors-txt5';
}
#content_div1 {
    grid-area: content_div1;
    /* background-color: rgb(234, 109, 109); */
    position: relative;
}

#content_div1 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
#content_div2 {
    grid-area: content_div2;
    position: relative;
    /* background-color: rgb(163, 133, 244); */
}
#content_div2 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#content_div3 {
    grid-area: content_div3;
    position: relative;
    /* background-color: rgb(243, 236, 176); */
}
#content_div3 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#content_div4 {
    grid-area: content_div4;
    position: relative;
    /* background-color: rgb(147, 246, 161); */
}
#content_div4 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#content_div5 {
    grid-area: content_div5;
    position: relative;
    /* background-color: rgb(236, 16, 250); */
}
#content_div5 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#sensors-txt1 {
    grid-area: sensors-txt1;
    font-size: 12px;
    color: #707070;
    position: absolute;
    top: 38%;
    left: 18%;
    transform: translate(-50%, -50%);
}
#sensors-txt2 {
    grid-area: sensors-txt2;
    font-size: 12px;
    color: #707070;
    position: absolute;
    top: 38%;
    left: 34%;
    transform: translate(-50%, -50%);
}
#sensors-txt3 {
    grid-area: sensors-txt3;
    font-size: 12px;
    color: #707070;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#sensors-txt4 {
    grid-area: sensors-txt4;
    font-size: 12px;
    color: #707070;
    position: absolute;
    top: 38%;
    left: 66%;
    transform: translate(-50%, -50%);
}
#sensors-txt5 {
    grid-area: sensors-txt5;
    font-size: 12px;
    color: #707070;
    position: absolute;
    top: 38%;
    left: 82%;
    transform: translate(-50%, -50%);
}

/* fourth */

.sub_Container4 {
    border: 1px solid white;
    margin: 2% 10% 2% 10%;
    background-color: #dff5f2;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 100%;
    grid-template-areas:
        'content5_text1 content5_text2 content5_text3'
        ' content5_text1 content5_text2 content5_text3'
        'content5_text1 content5_text2 content5_text3 ';
}
#content5_text1 {
    grid-area: content5_text1;
    position: relative;
    color: black;
}
#content5_text2 {
    grid-area: content5_text2;
    position: relative;
}
#content5_text3 {
    grid-area: content5_text3;
    position: relative;
    cursor: pointer;
}
.sub1_content5 {
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
    width: 9vh;
    height: 4.5vh;
    background: white;
    border-radius: 15px;
}

.sub2_content5 {
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
    width: 9vh;
    height: 4.5vh;
    background: #30a8ce;
    border-radius: 15px;
}

/* fourth */

.outertabDiv {
    position: relative;
    /* border: 2px solid green; */
}
.sub1_content5_klaw {
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
    width: 13vh;
    height: 4.5vh;
    background: white;
    border-radius: 15px;
}
.sub2_content5_klaw {
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
    width: 13vh;
    height: 4.5vh;
    background: #30a8ce;
    border-radius: 15px;
}

.outertabDiv-Hardware {
    position: absolute;
    top: 6%;
    left: 7.5%;
    font-family: 'Halcyon_SemiBold';
    height: 56.5vh;
    width: 56vw;
    /* border: 1px solid green; */
    overflow-y: auto;
    overflow-x: hidden;
}

.outertabDiv-HardwareText {
    height: 50vh;
    width: 156%;
    position: relative;

    color: black;
}
.outertabDiv-HardwareText > p {
    position: absolute;
    top: 50%;
    left: 32%;
    transform: translate(-50%, -50%);
    text-align: center;
}

/* .subText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
  } */

.hardwareInfo-section {
    height: auto;
    grid-gap: 3%;
    margin-bottom: 5%;
    display: grid;
    grid-template-columns: 23% 74%;
    grid-template-rows: 100%;
}

.isActivehardwareInfotrue {
    background: #dff5f2;
}
.isActivehardwareInfofalse {
    background: #dff5f2;
    opacity: 0.3;
}
.isActivehardwareInfoundefined {
    opacity: 0.3;
}
.isActivehardwareInfofalse {
    opacity: 0.3;
}
.hardwareInfo-section .portDetails-hw {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    /* width: 25%; */
}
.portDetails-hw_column {
    height: auto !important;
    flex-direction: column;
    gap: 10px;
}

.live_btn {
    height: 50px;
    width: 93%;
    border: 1px solid black;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    cursor: pointer;
}
.live_btn_disabled {
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: black !important;
}
.live_btn_opacity {
    opacity: 0.5 !important;
    cursor: default !important;
}

.live_btn_active {
    /* background: linear-gradient(to right, #feba45, #f97e61) !important; */
    background-color: rgb(37, 35, 94) !important;
    border: none;
    color: white;
}
.live_btn:hover {
    background: rgb(0, 0, 0, 0.5);
    color: #fff;
}

.hardwareInfo-section .portSlider-hw {
    display: flex;
    align-items: center;
    color: #000;
    justify-content: flex-end;
    /* background: #dff5f2; */
    /* border-radius: 10px; */
    user-select: none;
    width: 75%;
}

.activeBuzzertrue {
    background: #09b8a7;
    color: #fff;
}

.activeBuzzerfalse {
    background: #fff;
    cursor: pointer;

    color: #000;
}

.outertabDiv-Condation {
    display: flex;
    flex-direction: column;
    height: 550px;
    width: 908px;
    position: absolute;
    color: #000;
    top: 51.5%;
    left: 51%;
    transform: translate(-50%, -50%);
    /* border: 1px solid blue; */

    font-family: 'Halcyon_SemiBold';
    cursor: pointer;
}

.outertabDiv-Condation .margin-section {
    width: 85%;
    margin-left: 9%;
    /* border: 1px solid red; */
}

.outertabDiv-Condation .select-sensor {
    height: 57px;
    font-size: 18px;
    color: '#000';
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12%;
    left: 41%;
    transform: translate(-50%, -50%);
}

.outertabDiv-Condation .select-Condition {
    height: 110px;
    margin-top: 3%;
    background-color: rgb(235, 246, 250);
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 32%;
    left: 41%;
    transform: translate(-50%, -50%);
}

.select-Condition .item {
    position: relative;
    width: 85px;
    height: 85px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

.select-Condition .item img {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -0%);
}

.select-Condition .item p {
    font-size: 12px;
    color: #707070;
    position: absolute;
    bottom: 0%;
}

.outertabDiv-Condation .select-slider {
    height: max-content;
    margin-top: 3%;
    position: absolute;
    top: 56%;
    left: 45%;
    transform: translate(-50%, -50%);
}
.select-sensor-Read {
    margin-top: 10%;
    height: 100px;
    font-size: 18px;
    color: '#000';
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dff5f2;
    border-radius: 10px;
}

.select-sensor-Reads {
    margin-top: 7%;
    height: 100px;
    font-size: 18px;
    color: '#000';
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dff5f2;
    border-radius: 10px;
}

.Wait-main-container {
    position: relative;
    color: #000;
    height: 83%;
    width: 75%;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 100%;
    /* border: 1px solid blue; */
}

p {
    font-family: Halcyon_SemiBold;
}
p1 {
    font-family: Halcyon_SemiBold !important;
}

.sensor-txt {
    font-family: Halcyon_Medium !important;
}
.waitPropertyTxt {
    color: #000;
    font-size: 18px;

    font-family: Halcyon_SemiBold !important;
}

.inputDigit {
    border: 1px solid hsl(0, 0%, 0%);
}
.inputDigit:focus {
    outline: none;
}

.Wait-main-container .slider-section {
    height: 100%;
    width: 119%;
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, 25%);
}

.Wait-main-container .timer-section {
    height: 100%;
    width: 100%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tabDiv {
    display: inline-flex;
    position: absolute;
    top: -10.9vh;
    left: 35vw;
}
.tabButton {
    height: 6vh;
    width: 8vw;
    /* border-radius: 50px; */
    /* background-color: #311B92; */
    /* top: 90vh;
      right: 5vw;
      position: absolute; */
    color: white;
    text-align: center;
    line-height: 35px;
    z-index: 10;
    font-size: 1.5vw;
    word-wrap: break-word;
    /* padding-left:"10px"; */
    margin-left: 10px;
    margin-top: 10px;
    text-decoration: none;
    color: #311b92;
    border: 2px solid #311b92;
    border-top: none;
    border-radius: 0 0 10px 10px;
}
.tabActive {
    height: 8vh;
    width: 8vw;
    /* border-radius: 50px; */
    background-color: #311b92;
    /* top: 90vh;
    right: 5vw;
    position: absolute; */
    color: white;
    text-align: center;
    line-height: 35px;
    z-index: 10;
    font-size: 1.5vw;
    word-wrap: break-word;
    /* padding-left:"10px"; */
    margin-left: 5px;
    margin-top: 10px;
    border: 2px solid skyblue;
    border-top: none;
    border-radius: 0 0 10px 10px;
    text-decoration: none;
}
.waitDiv .rc-slider-rail {
    width: 103%;
}
.display_variable {
    color: black;
    font-size: 24px;
    font-weight: 600;
    margin: 10vh 0 0 25vw;
}

#Stepper-Main {
    width: 70%;
    height: 50px;
    /* background: green; */
    display: grid;
    grid-template-columns: 30% 70%;
    /* display: none; */
}

.STPM-MAIN {
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.Stepper-Toggle-Switch {
    width: 100%;
    height: 100%;
    /* background: red; */
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: 100%;
    z-index: 99999;
}

.checkBox-conatiner {
    height: 100%;
    width: 93%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: relative;
    user-select: none;
    padding: 1rem;
    border: none;
}
.checkBox-conatiner_1 {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    border-bottom-left-radius: 0% !important;
    border-bottom-right-radius: 0% !important;
    /* height: auto !important; */
    min-height: 80px !important;
}
.checkBox-conatiner-liveButtonEmpty {
    min-height: 80px !important;
}

.renderClickfalse {
    background-image: linear-gradient(to left, #e6e6e6, #7171719c);
}
.renderClicktrue {
    color: #ffff;
    background-image: linear-gradient(to right, #ffbc45, #f87b62);
}
.start-checkBox-conatiner {
    height: 60%;
    width: 75%;
    border-radius: 6px;
    position: relative;
    top: 25%;
    left: 10%;

    /* border: 2px solid #f75206; */
}

.start-renderClickfalse {
    /* content: "✔"; */
    /* color: black; */
    /* border: 2px solid #09090a; */

    background-image: linear-gradient(to left, #e6e6e6, #7171719c);
}
.start-renderClicktrue {
    /* content: "✔"; */
    /* border: 2px solid #f75206; */
    color: #ffff;
    /* display: flex;
    justify-content: center;
    align-items: baseline; */
    /* font-size: 50px; */
    background-image: linear-gradient(to right, #ffbc45, #f87b62);
}
.tick-Activetrue {
    display: block;
    position: relative;
    top: -18%;
    left: -5%;
    transform: translate(-50% -50%);
}
.tick-Activefalse {
    /* display: none; */
    visibility: hidden;
}

@media (max-width: 1400px) {
    .outertabDiv-Condation {
        position: relative;
        color: #000;
        /* height: 563px !important; */
        width: 62vw;
        top: 55% !important;
        left: 42%;
        transform: translate(-50%, -50%);
        overflow: hidden;
    }
    .select-sensor-Read {
        margin-top: 12%;
        height: 70px;
    }
}
